import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from "firebase/auth"
import logo from "../assets/logo5.png"

const Header = () => {
  const [pageState, setPageState] = useState("Sign in")
  const location = useLocation()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const auth = getAuth()
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("Profile")
      } else {
        setPageState("Sign in")
      }
    })
  }, [auth])
  const pathMatchRoute = (route) => {
    if (route === location.pathname) {
      return true
    }
  }
  return (
    <nav className=" bg-white border-b shadow-sm sticky top-0 z-40 pt-3 pb-3 flex items-center justify-between flex-wrap p-6">
     <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72">
       <img src={logo} onClick={() => navigate("/")} className="w-100 h-10 mr-2" alt="Logo" />
     </div>
     <div className="block lg:hidden">
       <button
         onClick={() => setIsOpen(!isOpen)}
         className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
       >
         <svg
           className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
           viewBox="0 0 20 20"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
         </svg>
         <svg
           className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
           viewBox="0 0 20 20"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
         </svg>
       </button>
     </div>
     <div
       className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}
     >
       <div className="text-sm lg:flex-grow lg:ml-56  ">
         <ul>
         <li
              className={` block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4 cursor-pointer text-base font-semibold text-blue-800 border-b-[3px] border-b-transparent 
              ${pathMatchRoute("/") && "text-red-800 border-b-red-700"}`}
              onClick={() => navigate("/")}
            >
              Home
            </li>
         
            <li
              className={` block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4 cursor-pointer text-base font-semibold text-blue-800 border-b-[3px] border-b-transparent 
              ${pathMatchRoute("/category/sale") && "text-red-800 border-b-red-700"}`}
              onClick={() => navigate("/category/sale")}
            >
              Sell
            </li>

         <li
              className={` block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4 cursor-pointer text-base font-semibold text-blue-800 border-b-[3px] border-b-transparent 
              ${pathMatchRoute("/category/rent") && "text-red-800 border-b-red-700"}`}
              onClick={() => navigate("/category/rent")}
            >
              Rent
            </li>

            <li
              className={` block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4 cursor-pointer  text-base font-semibold text-blue-800 border-b-[3px] border-b-transparent  
              ${pathMatchRoute("/offers") && "text-red-800 border-b-red-700"}`}
              onClick={() => navigate("/offers")}
            >
              Offers
            </li>
         {/* <li  className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4">
           Fourth Link
         </li> */}
         </ul>
       </div>
       <div>
       <button
            className={`cursor-pointer bg-red-600  py-2 px-4 rounded-full text-base font-semibold text-white border-b-[3px] border-b-transparent 
              ${(pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) && " border-b-white"}`}
            onClick={() => navigate("profile")}
          >
            {pageState}
          </button>
       </div>
     </div>
   </nav>
  )
}

export default Header
